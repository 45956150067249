<template>
    <PartRisk
        v-bind="props"
        :label="$t('Global.LifecycleRisk')"
        :heading="$t('Global.LifecycleRiskStatuses')"
        :options="LIFECYCLE_RISK_OPTIONS" />
</template>
<script setup lang="ts">
const props = defineProps<{ risk?: number; hideHelp?: boolean }>();
</script>
