<template>
    <Popover v-if="currentOption">
        <PopoverTrigger class="flex items-stretch">
            <Badge variant="outline" class="border-r-0 rounded-r-none">{{ props.label }}</Badge>
            <Badge
                :variant="currentOption.variant"
                :fill="currentOption.fill"
                :title="$t(currentOption.tooltip)"
                class="rounded-l-none">
                {{ $t(currentOption.label) }}
            </Badge>
        </PopoverTrigger>
        <PopoverContent v-if="!hideHelp" class="p-0 w-[30rem]" :align="'start'" hide-when-detached>
            <p class="text-lg font-bold m-2">{{ props.heading }}</p>
            <table class="table table-striped text-sm">
                <tbody>
                    <tr v-for="option in props.options" :key="option.label">
                        <td>
                            <Badge :variant="option.variant" :fill="option.fill">{{ $t(option.label) }}</Badge>
                        </td>
                        <td>{{ $t(option.tooltip) }}</td>
                    </tr>
                </tbody>
            </table>
        </PopoverContent>
    </Popover>
</template>
<script setup lang="ts">
const props = defineProps<{
    label: string;
    heading: string;
    risk?: number;
    options: Record<number, RiskOption>;
    hideHelp: boolean;
}>();

const currentOption = computed<RiskOption | undefined>(() =>
    props.risk === undefined ? undefined : props.options[props.risk]
);
</script>
