<template>
    <PartRisk
        v-bind="props"
        :label="$t('Global.SupplyChainRisk')"
        :heading="$t('Global.SupplyChainRiskStatuses')"
        :options="SUPPLYCHAIN_RISK_OPTIONS" />
</template>
<script setup lang="ts">
const props = defineProps<{ risk?: number; hideHelp?: boolean }>();
</script>
